<template>
  <div class="interesting__bottom">
    <articles-bottom-card
      v-for="(item, index) in items"
      :key="index"
      :item="item"
    />
  </div>
</template>

<script>
export default {
  name: "ArticlesBottomList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    ArticlesBottomCard: () =>
      import("@/components/articles/ArticlesBottomCard.vue"),
  },
};
</script>

<style lang="scss" scoped></style>
